import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  errors: Boolean = false;
  errorsMessages: Array<Object> = [{}];
  success: Boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: {hash: string}) => {

      const hash = window.location.pathname.replace(/\bconfirm\b/g, '').replace('//', '');

      this.authService.confirmRegistration(hash).subscribe((data) => {
        console.log(data);
        if(data['Success']) {
          this.success = true;
        } else {
          this.errors = true;
          this.errorsMessages = data['ConfirmCommunicationByCodeResult']['Warnings'];
          console.log(this.errorsMessages);
        }
      });
    });
  }

}
