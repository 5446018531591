import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { api } from '../../../environments/api';
import { ClinicData } from '../models/clinic/clinic-data.model';

@Injectable()
export class ClinicService {
  constructor(
    private httpService: HttpService,
    private baseService: BaseService
  ) {}

  registerClinic(data) {
    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AccountData: <ClinicData>data
    };

    return this.httpService.postBaseRequest(request, api.paths.simpleClinicRegistration)
  }
}
