import { Injectable } from '@angular/core';
import { UserData } from '../models/user-data.model';
import { User } from '../models/user.model';
import { api } from '../../../environments/api';
import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  constructor(
    private baseService: BaseService,
    private httpService: HttpService,
    private userService: UserService,
    private router: Router
  ) {}

  /*
  * Request for registration User
  * */

  signUp(userData: UserData) {
    const user: User = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: this.baseService.clearEmpty(userData)
    };

    return this.httpService.postBaseRequest(user, api.paths.simpleRegistration);
  }

  /*
  * Request for authorization User
  * */

  login(userData: UserData) {
    const user: User = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: userData
    };

    return this.httpService.postBaseRequest(user, api.paths.login);
  }

  /*
  * Request for reset Password User
  * */

  recover(userData: UserData) {
    const user: User = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: userData
    };

    return this.httpService.postBaseRequest(user, api.paths.restore);
  }

  /*
  * Request for logout User
  * */

  logOut() {

    const data: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: this.userService.getLocalUserData()['UserId'],
        SecretKey: this.userService.getUserSecretKey()
      }
    };

    this.userService.clearLocalData();

    this.router.navigate(['/sign-in'], {
      queryParams: {
        accessDenied: true
      }
    });

    return this.httpService.postBaseRequest(data, api.paths.logout);
  }

  /*
  * Validate Current Session of User
  * */

  sessionValidate() {
    const data: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: this.userService.getLocalUserData()['UserId'],
        SecretKey: this.userService.getUserSecretKey()
      }
    };

    return this.httpService.postBaseRequest(data, api.paths.isValid);
  }

  /*
  * Confirmation account registration
  * */

  confirmRegistration(hash: String) {
    const data: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: {
        Code: hash,
        UserId: this.userService.getLocalUserData()['UserId'],
        SecretKey: this.userService.getUserSecretKey()
      }
    };

    return this.httpService.postBaseRequest(data, api.paths.confirmCommunicationByCode);
  }

}
