import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { UserCurrentData } from '../../shared/models/user-current-data';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit, AfterViewInit {

  userCurrentData: UserCurrentData;
  showConfirmationEmail: Boolean = false;

  constructor(
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userCurrentData = this.userService.getLocalUserData();

    for(let key in this.userCurrentData) {
      !this.userCurrentData[key] ? this.userCurrentData[key] = 'Не указано'  : null;
    }

    this.userService.needConfirmation('Email').subscribe(data => {
      const message = data['NeedCommunicationConfirmationResult']['Message'];
      message === 'Средство связи требует подтверждения' ? this.showConfirmationEmail = true : this.showConfirmationEmail = false;
    });

    console.log(this.userCurrentData);
  }

  ngAfterViewInit() {

  }

  sendConfirmationRequest() {
    this.userService.sendConfirmationCode('Email').subscribe((data) => {
      console.log(data);
    });
  }

}
