import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserData} from '../../shared/models/user-data.model';
import {api} from '../../../environments/api';
import {UserService} from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  regErrorCheck: Boolean = false;
  regErrors: Array<String>;
  regSuccess: Boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit() {

    if(localStorage.getItem('user')) {
      this.router.navigate(['/account']);
    }

    this.form = new FormGroup({
      'emailPhone': new FormControl(null, [Validators.required, Validators.email]),
      // 'password': new FormControl(null, [Validators.required, Validators.minLength(5)]),
      'firstName': new FormControl(null),
      'surName': new FormControl(null),
      'middleName': new FormControl(null)
    });

    console.log(window.localStorage.getItem('user'));
  }

  onSubmit() {
    console.log('Submited', this.form);

    const value = this.form.value;

    const userData = <UserData>{
      Email: value.emailPhone,
      // Password: value.password,
      Firstname: value.firstName,
      Middlename: value.middleName,
      Surname: value.surName,
      TypeId: api.typeUser
    };

    this.authService.signUp(userData).subscribe((data) => {
      this.regErrorCheck = false;

      data = data['SimpleRegistrationResult'];
      const userData = data['UserData'];

      if(data['ErrorCode'] > 0) {
        let errors = [];

        data['Warnings'].forEach(function (d) {
          errors.push(d['Message']);
        });

        this.regErrors = errors;
        this.regErrorCheck = true;

      } else {

        localStorage.setItem('user-key', JSON.stringify(userData['SecretKey']));

        this.userService.getCurrentUserData(userData).subscribe((currentUserData) => {
          if(data['Success']) {
            localStorage.setItem('user', JSON.stringify(currentUserData['CurrentUserResult']['UserData']));
            this.regSuccess = true;
            console.log(currentUserData);
          }
        });
      }

    });
  }

}
