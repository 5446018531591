import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserCurrentData } from '../../shared/models/user-current-data';
import { UserService } from '../../shared/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserCurrentEditData } from '../../shared/models/user-current-edit-data';
import { BaseService } from '../../shared/services/base.service';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {

  userCurrentData : UserCurrentData;
  form: FormGroup;
  suggestions: Array<Object>;
  activeSuggestion: any;

  constructor(
    public userService: UserService,
    public baseService: BaseService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.userCurrentData = this.userService.getLocalUserData();
    this.activeSuggestion = null;
    this.suggestions = [];

    const email = this.userCurrentData.Email,
      phone = this.userCurrentData.Phone,
      firstName = this.userCurrentData.Firstname,
      surName = this.userCurrentData.Surname,
      middleName = this.userCurrentData.Middlename,
      age = this.userCurrentData.Age,
      birthday = this.userCurrentData.BirthDate,
      country = this.userCurrentData.Country,
      city = this.userCurrentData.City,
      address = this.userCurrentData.Address,
      aboutUser = this.userCurrentData.AboutSpecialist,
      gender = this.userCurrentData.Gender;

    this.form = new FormGroup({
      'email': new FormControl(email, [Validators.required, Validators.email]),
      'phone': new FormControl(phone),
      'passwordGroup': new FormGroup({
        'passwordOld': new FormControl(null),
        'password': new FormControl(null, Validators.minLength(5)),
        'passwordConfirm': new FormControl(null, Validators.minLength(5)),
      }, passwordMatchValidator),
      'firstName': new FormControl(firstName),
      'surName': new FormControl(surName),
      'middleName': new FormControl(middleName),
      'gender': new FormControl(gender, Validators.required),
      'age': new FormControl(age),
      'birthday': new FormControl(birthday),
      'country': new FormControl(country),
      'city': new FormControl(city),
      'address': new FormControl(address),
      'aboutUser': new FormControl(aboutUser),
    });

    function passwordMatchValidator(g: FormGroup) {
      return g.get('password').value === g.get('passwordConfirm').value
        ? null : {'mismatch': true};
    }
  }

  suggestAddress(event) {

    if(event.keyCode > 36 && event.keyCode < 41){
      if(event.keyCode == 38){
        event.preventDefault();
      }else if(event.keyCode == 40) {
        event.preventDefault();
      }
    }else{
      let address = event.target.value;
      let request = {
        'dadata': {
          'query': address,
          'count': 3
        }
      };

      this.baseService.postSuggestion(request).subscribe((data) => {
        this.suggestions = data['suggestions'];
      });
    }
  }
  //
  // suggestAddress(term) {
  //   // let address = this.form.controls.address.value;
  //   let request = {"dadata": {"query": term, "count": 3}};
  //
  //   this.httpClient.post(api.baseDomain + '/suggestions/api/4_1/rs/suggest/address', JSON.stringify(request), {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', 'application/json;charset=utf-8')
  //   }).subscribe((data) => {
  //     this.suggestions = data['suggestions'];
  //     console.log(data['suggestions'])
  //   })
  // }

  setAddress() {
    let data = this.activeSuggestion;
    let address = [];
    data.country ? this.form.value.country = data.country: null;
    data.city ? this.form.value.city = data.city : null;
    data.street_with_type ? address.push(data.street_with_type) : null;
    data.house_type && data.house ? address.push(data.house_type + ' ' + data.house) : null;
    data.block_type && data.block ? address.push(data.block_type + ' ' + data.block): null;
    data.flat_type && data.flat ? address.push(data.flat_type + ' ' + data.flat): null;
    this.form.value.address = address.join(', ');
    this.suggestions = [];
  }

  onSubmit() {
    console.log(this.form);

    const value = this.form.value,
          userId = this.userCurrentData.UserId,
          secretKey = this.userService.getUserSecretKey();

    const userCurrentEditData = <UserCurrentEditData>{
      UserId: userId,
      SecretKey: secretKey,
      Firstname: value.firstName,
      Middlename: value.middleName,
      Surname: value.surName,
      Gender: value.gender,
      Country: value.country,
      City: value.city,
      Address: value.address,
      Age: value.age,
      BirthDate: value.birthday,
      AboutSpecialist: value.aboutUser,
      Email: value.email,
      Phone: value.Phone,
      Password: value.passwordGroup.password,
      OldPassword: value.passwordGroup.passwordOld
    };

    this.userService.editCurrentUserData(userCurrentEditData).subscribe((data) => {
      console.log(data);

      data = data['EditUserResult'];

      if(data['Success']) {

        this.userService.getCurrentUserData(data['UserData']).subscribe((userData) => {

          userData = userData['CurrentUserResult'];

          if(userData['Success']) {
            localStorage.setItem('user', JSON.stringify(userData['UserData']));
            this.router.navigate(['/account']);

            console.log(userData);
          }
        });
      }
    });
  }

}
