import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { api } from '../../../environments/api';

import { HttpService } from './http.service';
import { BaseService } from './base.service';

import { UserCurrent } from '../models/user-current.model';
import { UserCurrentEditData } from '../models/user-current-edit-data';
import { UserId } from '../models/user/user-id.model';
import { UserEditKeys } from '../models/user-edit-keys';

@Injectable()
export class UserService {

  constructor(
    private baseService: BaseService,
    private httpService: HttpService,
    private httpClient: HttpClient,
  ) {}

  /*
  *
  * Confirmations account
  *
  * */

  /*
  * Request confirmation again
  * */

  sendConfirmationCode(type) {
    const data: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: {
        UserId: this.getLocalUserData()['UserId'],
        SecretKey: this.getUserSecretKey()
      }
    };

    return this.httpService.postBaseRequest(data, api.paths.sendConfirmationCode);
  }

  /*
  * Check confirmation needed
  * */

  needConfirmation(type) {
    const data: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: {
        UserId: this.getLocalUserData()['UserId'],
        SecretKey: this.getUserSecretKey()
      }
    };

    return this.httpService.postBaseRequest(data, api.paths.needCommunicationConfirmation);
  }

  /*
  *
  * Working with Users Data
  *
  * */

  /*
  * Get Data about current User from server
  * */

  getCurrentUserData(data) {

    const userData: UserId = {
      UserId: data['UserId'],
      SecretKey: data['SecretKey']
    };

    const currentUser: UserCurrent = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: userData,
    };

    return this.httpService.postBaseRequest(currentUser, api.paths.currentUser);
  }

  /*
  * Edit current User Data
  * */

  editCurrentUserData(userCurrentEditData: UserCurrentEditData) {
    const userEditKeys: UserEditKeys = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.baseService.clearEmpty(userCurrentEditData)
    };

    return this.httpService.postBaseRequest(userEditKeys, api.paths.editUser);

  }

  /*
  * Get Data about current User from Local Storage
  * */

  getLocalUserData() {
    return JSON.parse(localStorage.getItem('user'));
  }

  /*
  * Get current User Secret Key from Local Storage
  * */

  getUserSecretKey() {
    return localStorage.getItem('user-key');
  }

  /*
 * Get current User Type ID from Local Storage
 * */

  getUserType() {
    return JSON.parse(localStorage.getItem('user'))['TypeId'];
  }

  /*
  * Clear local storage data
  * */

  clearLocalData() {
    localStorage.removeItem('user-key');
    localStorage.removeItem('user');
  }

  /*
  *
  * Others
  *
  * */

}
