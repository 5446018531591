export const api = {
  production: false,
  sourceID: '872b875d-efb6-4a23-a24a-17781a8f314c',
  sourceSecretKey: 'r4L6vo1UWBC8CmvruXKv',
  typeUser: '534edd7d-6d00-4950-a392-ba0da3e35c82',
  baseDomain: 'https://marko.dsml.ru',
  baseUrl: '/0/ServiceModel/SiteIntegrationService.svc/',
  paths: {
    simpleRegistration: 'SimpleRegistration',
    login: 'Login',
    editUser: 'EditUser',
    currentUser: 'CurrentUser',
    restore: 'RestorePassword',
    logout: 'Logout',
    isValid: 'IsValid',
    confirmCommunicationByCode: 'ConfirmCommunicationByCode',
    sendConfirmationCode: 'SendConfirmationCode',
    needCommunicationConfirmation: 'NeedCommunicationConfirmation',
    simpleClinicRegistration: 'SimpleClinicRegistration',
  }
};
