import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { HttpService } from './services/http.service';
import { BaseService } from './services/base.service';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { ClinicService } from './services/clinic.service';

@NgModule({
  imports: [
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    BaseService,
    HttpService,
    AuthService,
    AuthGuard,
    UserService,
    ClinicService
  ]
})
export class SharedModule {
}
