import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class BaseService {

  constructor (
    private httpService: HttpService
  ) {}
  /*
  * Clear Empty Data Fields
  * */

  clearEmpty(data) {
    for(let propName of Object.keys(data)) {
      if(data[propName] == null || data[propName] === undefined){
        delete data[propName]
      }
    }
    return data
  }

  /*
  * Post Data Suggestion
  * */

  postSuggestion(request) {
    return this.httpService.postBaseCustomUrl(request, '/suggestions/api/4_1/rs/suggest/address');
  }
}
