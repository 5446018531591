import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserData } from '../../shared/models/user-data.model';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { api } from '../../../environments/api';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  form: FormGroup;
  errorCheck: Boolean = false;
  errors: Array<String>;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if(localStorage.getItem('user')) {
      this.router.navigate(['/account']);
    }

    this.form = new FormGroup({
      emailPhone: new FormControl(''),
      password: new FormControl(''),
      rememberMe: new FormControl('')
    });
  }

  onSubmit() {
    const userData = <UserData>{
      Email: this.form.value.emailPhone,
      Password: this.form.value.password
    };

    this.authService.login(userData).subscribe((response) => {
      console.log(response);

      response = response['LoginResult'];

      if(response['Success']) {

        const userData = response['UserData'];

        localStorage.setItem('user-key', userData['SecretKey']);

        this.userService.getCurrentUserData(userData).subscribe((currentUserData) => {

          currentUserData = currentUserData['CurrentUserResult'];

          if(currentUserData['Success']) {
            localStorage.setItem('user', JSON.stringify(currentUserData['UserData']));
            this.router.navigate(['/account']);

            console.log(currentUserData);
          }
        });
      }else{
        let errors = [];

        response['Warnings'].forEach(function (d) {
          errors.push(d['Message']);
        });

        this.errors = errors;
        this.errorCheck = true;
      }
    });
  }

}
