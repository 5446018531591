import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { AccountComponent } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { RegisterClinicComponent } from './register-clinic/register-clinic.component';

const routes: Routes = [
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard], children: [
      { path: '', component: AccountInfoComponent },
      { path: 'edit', component: AccountEditComponent },
      { path: 'register-clinic', component: RegisterClinicComponent }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
