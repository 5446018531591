import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.sessionValidate().pipe(map(request => {
      const session = request['IsValidResult']['Success'];

      if (session) {
        return true;
      } else {
        this.router.navigate(['/sign-in'], {
          queryParams: {
            accessDenied: true
          }
        });

        return false;
      }
    }));
  }
}
