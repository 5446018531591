import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserData } from '../../shared/models/user-data.model';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  form: FormGroup;
  errorCheck: Boolean = false;
  errors: Array<String>;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {

    this.form = new FormGroup({
      emailPhone: new FormControl('')
    });
  }

  onSubmit() {
    const userData = <UserData>{
      Email: this.form.value.emailPhone
    };

    this.authService.recover(userData).subscribe((data) => {

      console.log(data);

      if(data['RestorePasswordResult']['Success']) {
        this.router.navigate(['/sign-in']);
      }
    });
  }

}
