import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { RegisterClinicModule } from './register-clinic/register-clinic.module';

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    RegisterClinicModule
  ],
  declarations: [
    AccountComponent,
    AccountEditComponent,
    AccountInfoComponent
  ]
})
export class AccountModule { }
