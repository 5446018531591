import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterClinicComponent } from './register-clinic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { Ng2CompleterModule } from 'ng2-completer';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2CompleterModule
  ],
  declarations: [
    RegisterClinicComponent
  ]
})
export class RegisterClinicModule { }
