import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClinicData } from '../../shared/models/clinic/clinic-data.model';
import { BaseService } from '../../shared/services/base.service';
import { ClinicService } from '../../shared/services/clinic.service';
import { ClinicDetailsData } from '../../shared/models/clinic/partials/clinic-details-data.model';
import { ClinicMetro } from '../../shared/models/clinic/partials/clinic-metro.model';
import { ClinicMetroDataCollection } from '../../shared/models/clinic/partials/clinic-metro-data-collection.model';
import { HttpClient } from '@angular/common/http';
import { CompleterData, CompleterService } from 'ng2-completer';


@Component({
  selector: 'app-register-clinic',
  templateUrl: './register-clinic.component.html',
  styleUrls: ['./register-clinic.component.scss']
})
export class RegisterClinicComponent implements OnInit {

  form: FormGroup;
  suggestions: Array<Object>;
  activeSuggestion: any;
  metroSearch: string;
  metroService: CompleterData;
  metroData: any;

  constructor(
    private baseService: BaseService,
    private clinicService: ClinicService,
    private httpClient: HttpClient,
    private completerService: CompleterService
  ) {
    this.httpClient.get('/assets/metro.json').subscribe(response => {
      this.metroData = response;
      this.metroService = completerService.local(this.metroData, 'name', 'name').imageField("flag");
    });

  }

  ngOnInit() {

    this.form = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'phone': new FormControl(null, [Validators.required]),
      'city': new FormControl(null),
      'address': new FormControl(null),
      'site': new FormControl(null),
      'metro': new FormControl(null),
      'description': new FormControl(null)
    });

  }

  suggestAddress(event) {

    if (event.keyCode > 36 && event.keyCode < 41) {
      if (event.keyCode == 38) {
        event.preventDefault();
      } else if (event.keyCode == 40) {
        event.preventDefault();
      }
    } else {
      let address = event.target.value;
      let request = {
        'dadata': {
          'query': address,
          'count': 3
        }
      };

      this.baseService.postSuggestion(request).subscribe((data) => {
        this.suggestions = data['suggestions'];
      });
    }
  }

  setAddress() {
    let data = this.activeSuggestion;
    let address = [];
    // data.country ? this.form.value.country = data.country: null;
    data.city ? this.form.value.city = data.city : null;
    data.street_with_type ? address.push(data.street_with_type) : null;
    data.house_type && data.house ? address.push(data.house_type + ' ' + data.house) : null;
    data.block_type && data.block ? address.push(data.block_type + ' ' + data.block) : null;
    data.flat_type && data.flat ? address.push(data.flat_type + ' ' + data.flat) : null;
    this.form.value.address = address.join(', ');
    this.suggestions = [];
  }

  onSubmit() {
    let value = this.form.value;
    console.log(this.form.value);

     this.metroData.forEach((item) => {
      if(value.metro === item.name) {
        value.metro = item.value;
        return;
      }
    });

    const data = <ClinicData>{
      AccountName: value.name,
      Phone: value.phone,
      City: value.city,
      Address: value.address,
      Site: value.site,
      Description: value.description,
      DetailsData: <ClinicDetailsData>{
        AccountMetro: <ClinicMetro>{
          EntityName: 'BtcAccountMetro',
          MasterColumn: 'Id',
          DetailColumn: 'BtcAccountId',
          DataCollection: <ClinicMetroDataCollection>{
            Metro: value.metro
          }
        }
      }
    };

    this.clinicService.registerClinic(data).subscribe(response => {
      console.log(response);
    });

  }

}
