import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  userType: Boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {

  }

  onLogout() {
    this.authService.logOut().subscribe(data => {
      console.log(data);
    });
  }

  accountIsActive() {
    return this.router.isActive('/account', true) || this.router.isActive('/account/edit', false) ? 'active' : '';
  }

}
